import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {TabbedShowLayout, Tab} from 'react-admin'
import AmazonConnector from "../components/amazonConnector";

const ClientShow = props => (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label="general.tab.title">
                <FieldGuesser source={"name"}/>
                <FieldGuesser source={"hostUrl"}/>
                <FieldGuesser source={"apiKey"}/>
                <FieldGuesser source={"mail"}/>
                <FieldGuesser source={"additionalInfo"}/>
            </Tab>
            <Tab label="client.amazonSellerPartner">
                <AmazonConnector></AmazonConnector>
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default ClientShow;