import { useNotify, useRedirect } from 'react-admin';
import axios from 'utils/axios';
import { ApiPlatformAdminGetListParams } from '@api-platform/admin';
import { printError } from 'utils/errors';
import { entrypoint } from 'config';
import { dataProvider } from 'providers/dataProviderHydra';

const LIMIT = 300;

export const useApi = () => {

    const notify = useNotify();
    const redirect = useRedirect();

    /**
     * @param {*} resource : endpoint after `/api/`
     */
    const getByNonHydra = async (resource, isImage = false) => {
        const url = entrypoint + '/' + resource;
        try {
            const { data: result } = await axios.get(url, isImage ? { responseType: 'arraybuffer' } : {});
            if (isImage) {
                const arrayBufferView = new Uint8Array(result);
                const blob = new Blob([arrayBufferView], { type: 'image/png' });
                const urlCreator = window.URL || window.webkitURL;
                const imageUrl = urlCreator.createObjectURL(blob);
                return imageUrl;
            }
            return result;
        } catch (err) {
            printError(err, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return [];
        }
    }
    /**
     * @param {*} resource : endpoint after `/api/`
     */
    const postByNonHydra = async (resource, data) => {
        const url = entrypoint + '/' + resource;
        try {
            const { data: result } = await axios.post(url, data);
            return result;
        } catch (err) {
            printError(err, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return;
        }
    }

    const getIdFromHydraContext = (record) => {
        if (!record || !record['@id']) return undefined;
        const index = record['@id'].lastIndexOf('/');
        if (index >= 0) {
            return record['@id'].substr(index + 1);
        }
        return record['@id'];
    }

    /**
     * Hydra
     */

    const getList = async (resource: string, queryParams: ApiPlatformAdminGetListParams): Promise<{ data: any[], total: number }> => {
        try {
            return await dataProvider.getList(resource, queryParams);
        } catch (err) {
            printError(err, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return {};
        }
    }

    /**
     * @param {any[]} wholeArray will be changed after run
     * @param {string} resource 
     * @param {Omit<Params, 'limit', 'page'>} paramsWithoutPagination 
     * @param {number} page 
     * @param {number} perPage 
     * @returns {Promise<void>}
     */
     const recursiveGetList = async (wholeArray, resource, paramsWithoutPagination: ApiPlatformAdminGetListParams = {}, page = 1, perPage = LIMIT) => {
        try {
            const { data, total } = await getList(resource, {
                ...paramsWithoutPagination,
                pagination: { page, perPage },
            });
            if (!data?.length) return;
            wholeArray.push(...data);
            if (wholeArray.length < total) {
                await recursiveGetList(wholeArray, resource, paramsWithoutPagination, page + 1, perPage);
            }
        } catch (err) {
            printError(err, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
        }
    }

    const update = async (resource, id, data, previousData) => {
        try {
            const { data: updatedData } = await dataProvider.update(resource, { id, data, previousData });
            notify('messages.success', { type: 'success' });
            return { data: updatedData };
        } catch (error) {
            printError(error, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return { error };
        }
    }
    const create = async (resource, data) => {
        try {
            const { data: createdData } = await dataProvider.create(resource, { data });
            notify('messages.success', { type: 'success' });
            return { data: createdData };
        } catch (error) {
            printError(error, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return { error };
        }
    }
    const deleteOne = async (resource, id) => {
        try {
            const { data: deletedData } = await dataProvider.delete(resource, { id });
            notify('messages.success', { type: 'success' });
            return { data: deletedData };
        } catch (error) {
            printError(error, `resource: ${resource}`);
            notify('messages.error', { type: 'error' });
            return { error };
        }
    }

    return {
        getByNonHydra,
        postByNonHydra,
        getIdFromHydraContext,
        redirect,
        getList,
        recursiveGetList,
        update,
        create,
        deleteOne,
    }
}
