import { Route } from 'react-router-dom';
import { CustomRoutes } from "react-admin";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import defaultMessages from 'i18n/de';
import { dataProvider } from "providers/dataProviderHydra";
import { authProvider } from "providers/authProvider";
import { UserCreate, UserList, UserEdit, UserShow } from "./views/users";
import { ClientList, ClientEdit, ClientShow } from "./views/clients";
import Layout2 from 'layout2';
import useLocalStorageState from "use-local-storage-state";
import { getTheme } from "theme/theme2";
import LoggedOut from 'views/logged-out';
import Login from 'views/login';
import Dashboard from 'views/dashboard';
import Profile from 'views/profile';
import TwoFactorAuth from "views/TwoFactorAuth";
import { EmailTemplateCreate, EmailTemplateEdit, EmailTemplateList, EmailTemplateShow } from 'views/email-templates';

const i18nProvider = polyglotI18nProvider(langCode => {
    if (langCode && langCode !== 'de') {
        return import(`i18n/${langCode}`).then(messages => messages.default);
    }
    return defaultMessages;
}, 'de');

const App = () => {
    const [selectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    if (typeof window === "undefined") return <></>;
    return (
        <HydraAdmin
            entrypoint={window.origin}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            theme={getTheme(selectedThemeKey)}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout2}
        >
            {(permissions) => {
                return [
                    <CustomRoutes noLayout>
                        <Route path="/2fa" element={<TwoFactorAuth />} />
                        <Route path="/logged-out" element={<LoggedOut />} />
                    </CustomRoutes>,
                    <CustomRoutes>
                        <Route path="/profile" element={<Profile />} />
                    </CustomRoutes>,
                    <ResourceGuesser name="users" options={{ label: "menu.users" }} list={UserList} create={UserCreate} edit={UserEdit} show={UserShow} />,
                    <ResourceGuesser name="clients" options={{ label: "menu.clients" }} list={ClientList} edit={ClientEdit} show={ClientShow} />,
                    <ResourceGuesser name="email-templates" options={{ label: "menu.email_templates" }} list={EmailTemplateList} create={EmailTemplateCreate} edit={EmailTemplateEdit} show={EmailTemplateShow} />,
                ]
            }}
        </HydraAdmin>
    );
}

export default App;