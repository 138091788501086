import React, { useState } from 'react';
import { Form, required, TextInput, useLocaleState, useLogin, useNotify, useStore, useTranslate } from 'react-admin';
import { Box, Button, Card, CardActions, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import MuiSelect from 'components/MuiSelect';
import { LANG_OPTIONS } from 'i18n/config';
import { GRAY_BG } from 'theme/theme2';

interface FormValues {
    username?: string;
    password?: string;
}

const Login = () => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const login = useLogin();
    const [isSaveLogin, setIsSaveLogin] = useState(false);
    const [, setIsEnabled2FA] = useStore('enabled2FA', true);

    const [locale = 'de', setLocale] = useLocaleState();
    const handleLang = (e) => {
        setLocale(e.target.value);
    }

    const handleSubmit = (auth: FormValues) => {
        setLocale(locale);
        setLoading(true);
        login(auth, '/2fa')
            .then((enabled2FA) => {
                setIsEnabled2FA(enabled2FA || false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'warning',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundColor: GRAY_BG,
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '2em',
                            marginTop: '2em',
                        }}
                    >
                        <img src={'/logo-green-bf.png'} alt="logo" width={207} />
                    </Box>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box>
                            <TextInput
                                autoFocus
                                source="username"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                helperText={false}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ paddingTop: 1 }}>
                            <TextInput
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                helperText={false}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ paddingTop: 1 }}>
                            <MuiSelect
                                label={translate('pos.noLayout.language')}
                                options={Object.values(LANG_OPTIONS)}
                                value={locale}
                                onChange={handleLang}
                                fullWidth
                            />
                        </Box>
                        {false && <Box sx={{ paddingTop: 2 }}>
                            <FormControlLabel
                                label={translate('pos.noLayout.saveLogin')}
                                control={<Checkbox checked={isSaveLogin} onChange={e => setIsSaveLogin(e.target.checked)} />}
                            />
                        </Box>}
                    </Box>
                    <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            fullWidth
                            sx={{ marginTop: 1 }}
                        >
                            {loading && <CircularProgress size={25} thickness={2} />}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box sx={{ marginY: '1em', display: 'flex', justifyContent: 'center' }}>
                            {/* <Link to="/forgot-password" style={{ color: grey[700] }}> */}
                            <Button variant="text" onClick={() => notify('messages.comingSoon', { type: 'warning' })}>
                                {translate('pos.forgot_your_password')}
                            </Button>
                            {/* </Link> */}
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Form>
    );
}

export default Login;
