import React, { createContext, useContext, useState } from 'react';

const buttonSwitchLangId = "button-switch-lang";
const menuSwitchLangId = "menu-switch-lang";
const buttonUserId = "button-user-menu";
const menuUserId = "menu-user-menu";

const TourContext = createContext({
    menuSwitchLangId,
    buttonSwitchLangId,
    openSwitchLang: false,
    setOpenSwitchLang: (bool) => { },
    buttonUserId,
    menuUserId,
    openUserMenu: false,
    setOpenUserMenu: (bool) => { },
});

export const useTourContext = () => useContext(TourContext);

export const TourContextContainer = ({ children }) => {
    const [openSwitchLang, setOpenSwitchLang] = useState(false);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    return (
        <TourContext.Provider
            value={{
                menuSwitchLangId,
                buttonSwitchLangId,
                openSwitchLang,
                setOpenSwitchLang,
                buttonUserId,
                menuUserId,
                openUserMenu,
                setOpenUserMenu,
            }}
        >
            {children}
        </TourContext.Provider>
    )
}
