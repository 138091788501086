import React from 'react';
import { LayoutProps } from 'react-admin';
import AppLayout from './AppLayout';
import { TourContextContainer } from 'contexts/TourContext';
import ResponsiveContextContainer from 'contexts/ResponsiveContextContainer';

const Layout2 = (props: LayoutProps) => (
    <TourContextContainer>
        <ResponsiveContextContainer>
            <AppLayout {...props} />
        </ResponsiveContextContainer>
    </TourContextContainer>
);

export default Layout2;
