import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
    active: boolean;
}

const CircularProgressOverlay = ({
    active = false,
}: Props) => {
    if (!active) return null;
    return (
        <Box
            sx={{
                left: 0,
                top: 0,
                width: '100vw',
                height: '100vh',
                background: '#1111116b',
                zIndex: 100000,
                position: 'fixed',
                display: 'flex',
            }}
        >
          <CircularProgress style={{ margin: 'auto' }} />
        </Box>
    )
}

export default CircularProgressOverlay;
