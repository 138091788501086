import React from 'react';
import { EditButton, ListButton, ShowButton, TopToolbar } from 'react-admin';

type ActionsType = 'edit' | 'show' | 'create';

interface Props {
    type?: ActionsType;
}

const CustomTopToolbar = ({
    type = 'create',
}: Props) => {
    return (
        <TopToolbar>
            {type === 'edit' && <ShowButton />}
            {type === 'show' && <EditButton />}
            <ListButton />
        </TopToolbar>
    )
}

export default CustomTopToolbar;
