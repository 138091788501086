import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { datagridSx } from 'theme/theme2';

const ClientList = () => (
    <ListGuesser datagridSx={{ ...datagridSx }}>
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"hostUrl"} />
        <FieldGuesser source={"mail"} />
        <FieldGuesser source={"tags"} />
    </ListGuesser>
)

export default ClientList;
