export const LANG_OPTIONS = {
    de: {
        shortName: 'DE',
        longName: 'Deutsch',
        value: 'de',
        label: 'Deutsch',
    },
    en: {
        shortName: 'EN',
        longName: 'English',
        value: 'en',
        label: 'English',
    },
}
