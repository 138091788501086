export const printError = (errObject, additionalLog) => {
    const alt = {};
    Object.getOwnPropertyNames(errObject).forEach(key => {
        alt[key] = errObject[key];
    });
    console.log('printError:', alt);
    if (additionalLog) console.log('additional:', additionalLog);
}

export const onError = (error, translate, notify) => {
    printError(error);
    // eslint-disable-next-line
    const { code, detail, status, title } = (error.body?.errors || [])[0] || {};
    if (code === "1062") {
        notify('messages.duplicated', { type: 'error' });
        return;
    }
    if (code === "1451") {
        notify('messages.constraint', { type: 'error' });
        return;
    }
    notify(translate('messages.error') + (detail || ''), { type: 'error' });
}
