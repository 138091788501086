import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardActions, Typography } from '@mui/material';
import MuiColorButton from 'components/MuiColorButton';
import { grey } from '@mui/material/colors';
import { useTranslate } from 'react-admin';
import { GRAY_BG } from 'theme/theme2';

const LoggedOut = () => {    
    const translate = useTranslate();
    const navigate = useNavigate();

    const handleGotoLogin = () => {
        navigate('/login');
    }

    return (
        <form>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundColor: GRAY_BG,
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box sx={{ padding: '1em 1em 1em 1em' }}>
                        <Typography sx={{ color: theme => theme.palette.success.main }}>{translate('pos.logged_out')}</Typography>
                        <Typography sx={{ color: theme => theme.palette.success.main }}>{translate('pos.good_day')}</Typography>
                    </Box>
                    <CardActions sx={{ padding: '1em 1em 1em 1em' }}>
                        <MuiColorButton
                            colorset={grey}
                            variant="contained"
                            fullWidth
                            onClick={handleGotoLogin}
                        >
                            {translate('pos.go_to_login')}
                        </MuiColorButton>
                    </CardActions>
                </Card>
            </Box>
        </form>
    )
}

export default LoggedOut;
