import React from 'react';
import { Box } from '@mui/material';
import {
    Email as EmailIcon,
    Insights as InsightsIcon,
    Inventory as InventoryIcon,
} from '@mui/icons-material';
import { DashboardMenuItem, MenuItemLink, useTranslate } from 'react-admin';

const MenuItems = ({ dense }) => {
    const translate = useTranslate();
    // const [subMenuToggleState, setSubMenuToggleState] = useState({
    //     menuReports: false,
    // });
    // const handleToggle = (menuName) => {
    //     setSubMenuToggleState(state => ({ ...state, [menuName]: !state[menuName] }));
    // }
    return (
        <Box name="MENU-ITEMS" sx={{ paddingTop: 2, paddingBottom: 3 }}>
            <DashboardMenuItem />
            <MenuItemLink
                primaryText={translate('menu.users')}
                to="/users"
                leftIcon={<InventoryIcon />}
                state={{ _scrollToTop: true }}
                dense={dense}
            />
            <MenuItemLink
                primaryText={translate('menu.clients')}
                to="/clients"
                leftIcon={<InsightsIcon />}
                state={{ _scrollToTop: true }}
                dense={dense}
            />
            <MenuItemLink
                primaryText={translate('menu.email_templates')}
                to="/email-templates"
                leftIcon={<EmailIcon />}
                state={{ _scrollToTop: true }}
                dense={dense}
            />
        </Box>
    )
}

export default MenuItems;
