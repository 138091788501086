import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, useStore, useTranslate } from 'react-admin';
import { Box, Card, Typography } from '@mui/material';
import TwoFactorAuthCodeInput from 'components/TwoFactorAuthCodeInput';
import { GRAY_BG } from 'theme/theme2';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import { useApi } from 'contexts/useApi';
import { getCurrentUser } from 'providers/currentUser';

const TwoFactorAuth = () => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const { getByNonHydra, postByNonHydra } = useApi();
    const user = getCurrentUser();
    const [loading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isEnabled2FA] = useStore('enabled2FA', true);
    const [qrcodeUrl, setQrcodeUrl] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const getQrCode = async (userId) => {
        const result = await getByNonHydra(`user/${userId}/qrcode`, true);
        setQrcodeUrl(result);
    }
    const store2FASetting = async (userId, enable) => {
        const formData = new FormData();
        formData.append('enable', enable);
        const data = await postByNonHydra(`user/${userId}/2fa`, formData);
        if (data && data.success) return true;
        return false;
    }
    const processEnable2FASetting = async () => {
        setLoading(true);
        const isStored = await store2FASetting(user.id, true);
        if (isStored) {
            await getQrCode(user.id);
        } else {
            setErrorMsg(translate('pos.two_factor_auth.enable_failure'));
        }
        setLoading(false);
    }
    const gotoHome = () => {
        localStorage.setItem('verified2FA', true);
        navigate('/');
    }

    useEffect(() => {
        if (isEnabled2FA) return;
        processEnable2FASetting();
        // eslint-disable-next-line
    }, [isEnabled2FA]);

    useEffect(() => {
        if (!isVerified) return;
        gotoHome();
        // eslint-disable-next-line
    }, [isVerified]);

    const [waiting, setWaiting] = useState(true);
    useEffect(() => {
        setTimeout(() => setWaiting(() => false), 1000);
    }, []);
    if (waiting) {
        return <CircularProgressOverlay active={true} />
    }

    return (
        <>
            <CircularProgressOverlay active={loading} />
            <Form onSubmit={() => { }} noValidate>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        backgroundColor: GRAY_BG,
                    }}
                >
                    <Card sx={{ minWidth: 300, marginTop: '6em', padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ mb: 2 }}>
                            <img src={'/logo-green-bf.png'} alt="logo" width={207} />
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography>{translate('pos.two_factor_auth.description1')}</Typography>
                            <Typography>{translate('pos.two_factor_auth.description2')}</Typography>
                            <Typography>{translate('pos.two_factor_auth.description3')}</Typography>
                            {errorMsg && <Typography color='red' sx={{ marginTop: 2 }}>{errorMsg}</Typography>}
                        </Box>
                        {!isEnabled2FA &&
                            <>
                                {qrcodeUrl && <Typography>{translate('pos.two_factor_auth.scan_qr_code')}</Typography>}
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
                                    {qrcodeUrl
                                        ? <img src={qrcodeUrl} alt="QR Code" height={240} />
                                        : <Box sx={{ height: 240, width: 240 }} />
                                    }
                                </Box>
                            </>
                        }
                        <Box>
                            {!isEnabled2FA && <Typography sx={{ marginBottom: 1 }}>{translate('pos.two_factor_auth.after_scan')}</Typography>}
                            <TwoFactorAuthCodeInput setLoading={setLoading} onComplete={setIsVerified} />
                        </Box>
                    </Card>
                </Box>
            </Form>
        </>
    )
}

export default TwoFactorAuth;
