import { printError } from "utils/errors";

interface User {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: stirng;
    language: string;
    isEmployee: boolean;
    active: boolean;
    enableLogin: boolean;
}

export const setCurrentUser = (user: User) => {
    localStorage.setItem('currentUser', user ? JSON.stringify(user) : user);
}

export const removeCurrentUser = () => {
    // localStorage.removeItem('currentUser');
}

export const getCurrentUser = (): User => {
    const value = localStorage.getItem('currentUser');
    if (value && value !== 'undefined') {
        try {
            return JSON.parse(value);
        } catch (err) {
            printError(err);
        }
    }
    return {};
}
