import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';

const MuiColorButton = styled(Button)(({ theme, colorset }) => ({
    color: theme.palette.getContrastText(colorset[700]),
    backgroundColor: colorset[700],
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: colorset[900],
    },
}));

MuiColorButton.propTypes = {
    colorset: PropTypes.object,
};

export default MuiColorButton;
