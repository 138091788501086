import React from 'react';
import { Typography } from '@mui/material';
import { AppBar } from 'react-admin';
import SwitchLang from './switch-lang';
import { OUR_COLORS } from 'theme/theme2';
import CustomUserMenu from 'layout2/NavDrawer/CustomUserMenu';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';

const CustomAppBar = (props) => {
    const { primaryColor, selectedThemeKey } = useResponsiveContext();
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
            sx={{
                color: primaryColor,
                transform: "none !important",
                visibility: "visible !important",
                padding: "0 !important",
                boxShadow: `0px 2px 1px -1px ${OUR_COLORS[selectedThemeKey][800]}, 0px 1px 1px 0px ${OUR_COLORS[selectedThemeKey][600]}, 0px 1px 3px 0px ${OUR_COLORS[selectedThemeKey][400]}`,
            }}
        >
            {/* Logo */}
            {/* <Box sx={{ paddingTop: '6px', paddingRight: 2 }}>
                <Link to="/">
                    {selectedThemeKey === "GREEN" ?
                        <img src='/logo-green-bf.png' alt="logo" height={24} />
                        :
                        <img src='/logo-blue-pixi.png' alt="logo" height={38} />
                    }
                </Link>
            </Box> */}
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    marginRight: 'auto',
                }}
                id="react-admin-title"
            />
            <SwitchLang />
        </AppBar>
    )
}

export default CustomAppBar;
