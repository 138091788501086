import React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import CustomAppBar from './AppBar';
import CustomMenu from './NavDrawer/Menu';
import { ReactQueryDevtools } from 'react-query/devtools';

const AppLayout = (props: LayoutProps) => {
    return (
        <>
            <Layout
                {...props}
                appBar={CustomAppBar}
                menu={CustomMenu}
            />
            <ReactQueryDevtools initialIsOpen={false} />
        </>
    )
}

export default AppLayout;
