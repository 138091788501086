import React from 'react';
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import CustomTopToolbar from 'components/CustomTopToolbar';
import BodyInput from '../custom/BodyInput';

const EmailTemplatesCreate = () => {
    return (
        <CreateGuesser actions={<CustomTopToolbar />}>
            <InputGuesser source='title' />
            <InputGuesser source='subject' />
            <BodyInput source='body' />
        </CreateGuesser>
    )
}

export default EmailTemplatesCreate;
