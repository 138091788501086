import { defaultTheme } from 'react-admin';
import { colors, Theme } from '@mui/material';

export const DRAWER_WIDTH = 280;
export const CLOSED_DRAWER_WIDTH = 55;
export const MUI_COLORS = colors;
export const OUR_COLORS = {
    BLACK: '#000',
    WHITE: '#fff',
    PINK: '#fa1a96',
    GOLD: '#ffb759',
    GRAY: {
        200: '#f6f6f6',
        300: '#f0f0f0',
        400: '#cccccc',
        500: '#a5a5a5',
        600: '#7e7e7e',
        700: '#606060',
        800: '#000000',
    },
    GREEN: {
        400: '#c0f2d9',
        500: '#7fe5b3',
        600: '#40d88b',
        700: '#00cc65',
        800: '#00ad6d',
    },
    BLUE: {
        400: '#90caf9',
        500: '#64b5f6',
        600: '#2196f3',
        700: '#0055aa',
        800: '#2979ff',
    },
};
export const GRAY_BG = OUR_COLORS.GRAY[600];
export const GRAY_BG_HOVER = OUR_COLORS.GRAY[700];
export const COLORS = {
    GREEN: process.env.REACT_APP_THEME_COLORS_PRIMARY_GREEN,
    BLUE: process.env.REACT_APP_THEME_COLORS_PRIMARY_BLUE,
    SECONDARY: process.env.REACT_APP_THEME_COLORS_SECONDARY,
}
export const spacing = (num) => {
    return `${num * 8}px`;
}

/**
 * @param {'GREEN' | 'BLUE'} primaryKey 
 */
 export const getTheme = (primaryKey = 'GREEN'): Theme => {
    const primaryColor = COLORS[primaryKey] || COLORS.GREEN;
    const secondaryColor = COLORS.SECONDARY;
    const primaryFontFamily = process.env.REACT_APP_THEME_FONT_FAMILY;
    const theme: Theme = {
        ...defaultTheme,
        palette: {
            primary: {
                main: primaryColor,
                contrastText: secondaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
            background: {
                default: secondaryColor,
                paper: secondaryColor,
            },
        },
        typography: {
            fontFamily: primaryFontFamily,
        },
        sidebar: {
            width: DRAWER_WIDTH,
            closedWidth: CLOSED_DRAWER_WIDTH,
        },
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: primaryColor,
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        backgroundColor: primaryColor,
                    },
                    modal: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: '40px !important',
                    },
                },
            },
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        color: secondaryColor,
                        '& svg': {
                            color: secondaryColor,
                        },
                        '&.RaMenuItemLink-active': {
                            color: primaryColor,
                            '& svg': {
                                color: primaryColor,
                            },
                        },
                        '&:hover': {
                            backgroundColor: GRAY_BG_HOVER,
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: '12px',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        minHeight: '36px',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        minHeight: '36px',
                        backgroundColor: OUR_COLORS.WHITE,
                        border: `1px solid ${OUR_COLORS.GRAY[400]}`,
                        borderRadius: 6,
                        marginRight: 8,
                        paddingTop: `8px !important`,
                        paddingBottom: `8px !important`,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: OUR_COLORS.GRAY[700],
                            color: OUR_COLORS.WHITE,
                        },
                        '&.Mui-selected': {
                            border: 'none',
                            backgroundColor: primaryColor,
                            color: OUR_COLORS.WHITE,
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            '& svg': {
                                color: primaryColor,
                            },
                        },
                    },
                },
            },
            MuiTabScrollButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            '& svg': {
                                color: primaryColor,
                            },
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    deleteIcon: {
                        '&:hover': {
                            color: primaryColor,
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        wordBreak: 'break-word',
                    },
                },
            },
        },
    };
    return theme;
}

export const commonStyles = {
    content_root: {
        border: '1px solid #cccccc',
        borderRadius: '5px',
        padding: '2em',
        backgroundColor: OUR_COLORS.WHITE,
    }
}

export const datagridSx = {
    '& .column-undefined': {
        width: 120,
        paddingLeft: 1,
        paddingRight: 1,
    },
}
