export const COUNTRIES_OPTIONS = {
    de: {
        shortName: 'DE',
        longName: 'Deutschland',
        value: 'germany',
        label: 'Deutschland'
    },
    ca: {
        shortName: 'CA',
        longName: 'Canada',
        value: 'canada',
        label: 'Canada'
    },
    us: {
        shortName: 'US',
        longName: 'USA',
        value: 'us',
        label: 'Usa'
    },
    mx: {
        shortName: 'MX',
        longName: 'Mexiko',
        value: 'mexico',
        label: 'Mexiko'
    },
    br: {
        shortName: 'BR',
        longName: 'Brasilien',
        value: 'brazil',
        label: 'Brasilien'
    },
    es: {
        shortName: 'ES',
        longName: 'Spanien',
        value: 'spain',
        label: 'Spanien'
    },
    gb: {
        shortName: 'GB',
        longName: 'Vereinigtes Königreich',
        value: 'uk',
        label: 'Vereinigtes Königreich'
    },
    fr: {
        shortName: 'FR',
        longName: 'Frankreich',
        value: 'france',
        label: 'Frankreich'
    },
    nl: {
        shortName: 'NL',
        longName: 'Niederlande',
        value: 'netherlands',
        label: 'Niederlande'
    },
    it: {
        shortName: 'IT',
        longName: 'Italien',
        value: 'italy',
        label: 'Italien'
    },
    se: {
        shortName: 'SE',
        longName: 'Schweden',
        value: 'sweden',
        label: 'Schweden'
    },
    pl: {
        shortName: 'PL',
        longName: 'Polen',
        value: 'poland',
        label: 'Polen'
    },
    tr: {
        shortName: 'TR',
        longName: 'Türkei',
        value: 'turkey',
        label: 'Türkei'
    },
}