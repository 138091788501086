import React from 'react';
import { Title } from 'react-admin';
import { Box, Card, CardContent } from '@mui/material';

const Dashboard = () => {
    return (
        <Card>
            <Title title='Dashboard' />
            <CardContent>
                <Box>The contents are coming soon...</Box>
            </CardContent>
        </Card>
    )
}

export default Dashboard;
