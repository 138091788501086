import React, { useState } from "react";
import {
    useNotify,
    useRecordContext,
    useRefresh,
    useTranslate,
    TextInput, required, Form
} from "react-admin";
import { Box, Button } from "@mui/material";
import { useApi } from "contexts/useApi";
import MuiModal from "components/MuiModal";
import MuiSelect from "components/MuiSelect";
import CircularProgressOverlay from "components/CircularProgressOverlay";
import {COUNTRIES_OPTIONS} from "./countries";

const AmazonConnector = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    // eslint-disable-next-line
    const refresh = useRefresh();
    const record = useRecordContext();
    const { getIdFromHydraContext, getByNonHydra, postByNonHydra } = useApi();
    const clientId = getIdFromHydraContext(record);
    const [loading, setLoading] = useState(false);
    const [authorizationUrl, setAuthorizationUrl] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [country = 'germany', setCountry] = useState('germany')
    const [shopsName = '', setShopsName] = useState('')
    const [disabled, setDisabled] = useState(true)
    // const connected = Boolean(record.additionalInfo); we can not use this here at the moment because of clients can have multiple amazon accounts
    const connected = false

    const handleCountry = (e) => {
        setCountry(e.target.value);
    }

    const handleShopsName = (e) => {
        if (e.target.value !== undefined && e.target.value.length > 0) {
            setShopsName(e.target.value)
            setDisabled(false)
        } else {
            setShopsName('')
            setDisabled(true)
        }
    }

    const handleSend = async () => {
        setIsSending(true);
        const hydraData = await getByNonHydra('email-templates');
        const templates = hydraData['hydra:member'];
        if (!templates?.length) {
            notify('pos.email_template.not_found', { type: 'error' });
            setIsSending(false);
            return;
        }
        const formData = new FormData();
        formData.append('templateId', templates[0].id);
        formData.append('amazonUrl', authorizationUrl);
        formData.append('clientId', clientId);
        const data = await postByNonHydra(`email`, formData);
        if (!data) {
            notify('messages.error', { type: 'error' });
        } else {
            notify('messages.success', { type: 'success' });
            setOpenModal(false);
        }
        setIsSending(false);
    }

    const handleGetUrl = async () => {
        setLoading(true);
        const data = await getByNonHydra(`clients/amazon-sp/${clientId}/${country}/${shopsName}/${record.hostUrl}/authorize`)
        if (typeof data.authorizationUrl !== "undefined") {
            setAuthorizationUrl(data.authorizationUrl);
            setOpenModal(true);
        } else {
            notify("client.notification.amazonConnectError", "error");
        }
        setLoading(false);
    }

    // const handleGetRefreshToken = async () => {
    //     setLoading(true);
    //     const data = await postByNonHydra(`clients/${clientId}/refreshTokenToBrickfox`, {
    //         name: record.name,
    //         hostUrl: record.hostUrl,
    //         apiKey: record.apiKey,
    //         additionalInfo: record.additionalInfo,
    //     });
    //     if (!data) {
    //         notify("client.notification.amazonConnectError", "error");
    //     }
    //     setLoading(false);
    // }

    // const openAmazonConnection = (url) => {
    //     const width = 650;
    //     const height = 650;

    //     const pos = {
    //         // eslint-disable-next-line no-restricted-globals
    //         x: (screen.width / 2) - (width / 2),
    //         // eslint-disable-next-line no-restricted-globals
    //         y: (screen.height / 2) - (height / 2)
    //     };
    //     const features = `width=${width} height=${height} left=${pos.x} top=${pos.y}`;

    //     window.open(url, '_blank', features);
    //     window.onmessage = function (e) {
    //         if (e.data === 'brickfox-clienthub-amazon-popup') {
    //             refresh()
    //         }
    //     };
    // }

    return (
        <>
            <Form noValidate>
                <Box>
                    <TextInput
                        autoFocus
                        source="shopsName"
                        label={translate('general.shopsName')}
                        validate={required()}
                        fullWidth
                        helperText={false}
                        variant="outlined"
                        name={"shopsName"}
                        onChange={handleShopsName}
                    />
                </Box>
            </Form>
            <MuiSelect
                label={translate('general.country')}
                options={Object.values(COUNTRIES_OPTIONS)}
                value={country}
                onChange={handleCountry}
                fullWidth
            />
            <CircularProgressOverlay active={loading} />
            <Box sx={{ my: 1 }}>
                <Button
                    name={"connect"}
                    disabled={disabled}
                    onClick={handleGetUrl}
                    size="small"
                    variant="outlined"
                    sx={{ minWidth: 132 }}
                >
                    {
                        translate('client.amazonSellerPartnerConnect')
                        // connected
                        //     ? translate('client.amazonSellerPartnerConnected')
                        //     : translate('client.amazonSellerPartnerConnect')
                    }
                </Button>
            </Box>
            {/*<Box sx={{ my: 1 }}>*/}
            {/*    <Button*/}
            {/*        disabled={!connected}*/}
            {/*        onClick={handleGetRefreshToken}*/}
            {/*        size="small"*/}
            {/*        variant="outlined"*/}
            {/*        sx={{ minWidth: 132 }}*/}
            {/*    >*/}
            {/*        {translate('client.refreshToken')}*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            <MuiModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                onCloseOutsideClick={() => { }}
                title={connected ? "Refresh Token" : "Amazon Connection URL"}
                fullWidth
                dialogContents={
                    <Box sx={{ padding: 2 }}>
                        <CircularProgressOverlay active={isSending} />
                        <a href={authorizationUrl} target="_blank" rel="noreferrer">
                            {connected ? "refreshToken" : authorizationUrl}
                        </a>
                    </Box>
                }
                dialogActions={
                    <>
                        <Button variant="contained" disabled={connected} onClick={handleSend}>{translate('pos.send_email')}</Button>
                        <Button variant="outlined" onClick={() => setOpenModal(false)}>{translate('pos.cancel')}</Button>
                    </>
                }
            />
        </>
    )
}

export default AmazonConnector;
