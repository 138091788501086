import React, { useEffect, useState } from 'react';
import { getCurrentUser, setCurrentUser } from 'providers/currentUser';
import { Box, Button, Switch, Typography } from '@mui/material';
import { useNotify, useTranslate } from 'react-admin';
import MuiModal from 'components/MuiModal';
import TwoFactorAuthCodeInput from 'components/TwoFactorAuthCodeInput';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import { useApi } from 'contexts/useApi';

const TwoFactorAuthSetting = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const { getByNonHydra, postByNonHydra } = useApi();
    const user = getCurrentUser();
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    useEffect(() => {
        setIsEnabled(user.two_factor?.enabled || false);
    }, [user.two_factor?.enabled]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [qrcodeUrl, setQrcodeUrl] = useState('');

    const getQrCode = async (userId) => {
        const result = await getByNonHydra(`user/${userId}/qrcode`, true);
        setQrcodeUrl(result);
    }
    const store2FASetting = async (userId, enable) => {
        const formData = new FormData();
        formData.append('enable', enable);
        const data = await postByNonHydra(`user/${userId}/2fa`, formData);
        if (data && data.success) return true;
        return false;
    }

    const handleChange = async (e) => {
        const { checked } = e.target;
        if (checked) {
            setOpenModal(true);
            setLoading(true);
            const isStored = await store2FASetting(user.id, true);
            if (isStored) {
                await getQrCode(user.id);
            } else {
                setOpenModal(false);
            }
            setLoading(false);
        } else {
            setOpenConfirm(true);
        }
    }

    const handleDisable = async () => {
        setLoading(true);
        const isStored = await store2FASetting(user.id, false);
        if (isStored) {
            setIsEnabled(false);
            setVerified(false);
            const two_factor = user.two_factor || {};
            two_factor.enabled = false;
            setCurrentUser({ ...user, two_factor });
            notify('pos.two_factor_auth.disable_success', { type: 'success' })
        } else {
            notify('pos.two_factor_auth.disable_failure', { type: 'error' });
        }
        setLoading(false);
        setOpenConfirm(false);
    }

    useEffect(() => {
        if (!verified) return;
        setOpenModal(false);
        setIsEnabled(true);
        // const two_factor = user.two_factor || {};
        // two_factor.enabled = true;
        // setCurrentUser({ ...user, two_factor });
        // eslint-disable-next-line
    }, [verified]);

    return (
        <>
            <Box sx={{ border: '1px solid #ccc', padding: 2 }}>
                <Typography sx={{ fontWeight: 'bold' }}>{translate('pos.two_factor_auth.title')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                    <Typography>{`${isEnabled ? translate('pos.two_factor_auth.turn_off') : translate('pos.two_factor_auth.turn_on')} ${translate('pos.two_factor_auth.with_google_auth_app')}`}</Typography>
                    <Switch
                        checked={isEnabled}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            <MuiModal
                title={<Typography color="error">{translate('pos.two_factor_auth.confirm_disable_title')}</Typography>}
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                dialogContents={
                    <Box>
                        <CircularProgressOverlay active={loading} />
                        <Typography>{translate('pos.two_factor_auth.confirm_disable')}</Typography>
                    </Box>
                }
                dialogActions={
                    <>
                        <Button variant="outlined" onClick={() => setOpenConfirm(false)}>{translate('pos.cancel')}</Button>
                        <Button variant="contained" color="error" onClick={handleDisable}>{translate('pos.two_factor_auth.disable')}</Button>
                    </>
                }
            />
            <MuiModal
                title={translate('pos.two_factor_auth.with_google_auth_app')}
                open={openModal}
                onClose={() => setOpenModal(false)}
                onCloseOutsideClick={() => { }}
                dialogContents={
                    <Box>
                        <CircularProgressOverlay active={loading} />
                        <Typography>{translate('pos.two_factor_auth.scan_qr_code')}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
                            <img src={qrcodeUrl} alt="QR Code" height={240} />
                        </Box>
                        <Box sx={{ margin: 3 }}>
                            <Typography sx={{ marginBottom: 1 }}>{translate('pos.two_factor_auth.after_scan')}</Typography>
                            <TwoFactorAuthCodeInput setLoading={setLoading} onComplete={setVerified} />
                        </Box>
                    </Box>
                }
            />
        </>
    )
}

export default TwoFactorAuthSetting;
