import React, { useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';

type ValueOptions = {
    value: any;
    label: string;
}

type Props = SelectProps & {
    label: string;
    options: ValueOptions[];
    sx?: any;
    fullWidth?: boolean;
}

const useStyles = makeStyles(theme => ({
    input_label: {
        transform: 'translate(14px, 9px) scale(1)',
    },
    shrink_label: {
        transform: 'translate(14px, -9px) scale(0.75)',
    },
}));

const MuiSelect = (props: Props) => {
    const { label, options, sx, ...rest } = props;
    const fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
    const labelId = useMemo(() => uuidv4(), []);
    const classes = useStyles();
    return (
        <FormControl fullWidth={fullWidth} sx={{ marginTop: 1, marginBottom: 0.5, minWidth: 223, ...sx }}>
            <InputLabel
                id={labelId}
                classes={{
                    root: classes.input_label,
                    shrink: classes.shrink_label,
                }}
            >
                {label}
            </InputLabel>
            <Select
                input={<OutlinedInput label={label} />}
                labelId={labelId}
                margin="dense"
                size="small"
                {...rest}
            >
                {options.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>{label || value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MuiSelect;
