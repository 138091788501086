import React, { useState } from 'react';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useLocaleState } from 'react-admin';
import { Check as CheckIcon, Language as LanguageIcon } from '@mui/icons-material';
import { LANG_OPTIONS } from 'i18n/config';
import { useTourContext } from 'contexts/TourContext';

const SwitchLang = () => {

    // Switch Language Menu
    const { buttonSwitchLangId, menuSwitchLangId, openSwitchLang, setOpenSwitchLang } = useTourContext();
    const [anchorSwitchLangButton, _setAnchorSwitchLangButton] = useState(null);
    const setAnchorSwitchLangButton = (anchor) => {
        _setAnchorSwitchLangButton(anchor);
        setOpenSwitchLang(Boolean(anchor));
    }
    const handleCloseSwitchLang = () => setAnchorSwitchLangButton(null);

    const [locale = 'de', setLocale] = useLocaleState();
    const handleLang = (langCode) => () => {
        setLocale(langCode);
        handleCloseSwitchLang();
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                id={buttonSwitchLangId}
                aria-controls={openSwitchLang ? "menu-switch-lang" : undefined}
                aria-haspopup="true"
                aria-expanded={openSwitchLang ? "true" : undefined}
                onClick={(e) => setAnchorSwitchLangButton(e.currentTarget)}
                startIcon={<LanguageIcon />}
                color="inherit"
            >
                {LANG_OPTIONS[locale].shortName}
            </Button>
            {openSwitchLang && <Box id={menuSwitchLangId} sx={{ position: 'absolute', top: 40, right: 0, width: 140, height: 90 }} />}
            <Menu
                id="menu-switch-lang"
                anchorEl={anchorSwitchLangButton}
                open={openSwitchLang}
                onClose={handleCloseSwitchLang}
                MenuListProps={{ "aria-labelledby": buttonSwitchLangId }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 30,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {Object.keys(LANG_OPTIONS).map(langCode => (
                    <MenuItem
                        key={langCode}
                        selected={langCode === locale}
                        onClick={handleLang(langCode)}
                    >
                        <ListItemIcon>{langCode === locale && <CheckIcon />}</ListItemIcon>
                        <ListItemText>{LANG_OPTIONS[langCode].longName}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default SwitchLang;
