import React from 'react';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import CustomTopToolbar from 'components/CustomTopToolbar';
import BodyField from '../custom/BodyField';

const EmailTemplatesShow = () => {
    return (
        <ShowGuesser actions={<CustomTopToolbar type='show' />}>
            <FieldGuesser source="title" />
            <FieldGuesser source="subject" />
            <BodyField source="body" />
        </ShowGuesser>
    )
}

export default EmailTemplatesShow;
