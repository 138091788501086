import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import {ReferenceInput, SelectInput} from 'react-admin';

const UserCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source={"firstName"}/>
        <InputGuesser source={"lastName"}/>
        <InputGuesser source={"mail"}/>
        <InputGuesser source={"password"}/>
        <ReferenceInput
            source="mainAccessGroup"
            reference="access-groups"
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <InputGuesser source={"accessGroups"}/>
    </CreateGuesser>
);

export default UserCreate;