import React from 'react';
import { useRecordContext } from 'react-admin';

const BodyField = (props) => {
    const record = useRecordContext(props);
    if (!record) return null;
    return (
        <div dangerouslySetInnerHTML={{ __html: record.body }} />
    )
}

export default BodyField;
