import React from 'react';
// import { Link } from 'react-router-dom';
import { MenuProps, useSidebarState, useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import MenuItems from './MenuItems';
import { CLOSED_DRAWER_WIDTH, COLORS, DRAWER_WIDTH, GRAY_BG } from 'theme/theme2';
import { useResponsiveContext } from 'contexts/ResponsiveContextContainer';

const Menu = ({ dense = false }: MenuProps) => {
    const translate = useTranslate();
    const [open] = useSidebarState();
    const { isPhone } = useResponsiveContext();

    return (
        <Box
            name="MENU-CONTAINER"
            sx={{
                width: open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH,
                height: isPhone ? '100vh' : undefined,
                backgroundColor: GRAY_BG,    // theme => theme.palette.primary.main
                transition: theme => theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}
        >
            <Box
                name="MENU-CONTENTS"
                sx={{
                    height: theme => `calc(100vh - ${undefined ? '0px' : '3em'} - ${theme.spacing(7)})`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <MenuItems dense={dense} />
            </Box>
            <Box
                name="FOOTER"
                sx={{ paddingY: 2, display: 'flex', justifyContent: 'center' }}
            >
                {open && <Typography color={COLORS.SECONDARY}>{translate('pos.footer.msg1')}</Typography>}
            </Box>
        </Box>
    )
}

export default Menu;
