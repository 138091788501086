import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'

import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import draftToMarkdown from 'draftjs-to-markdown'

import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './style.css'

const DraftEditor = forwardRef(({
    isHtmlOrMarkdown = true,
    initialValue,  // markdown-text | html-text
    onChange = (value => {}),
}, ref) => {
    const editor = useRef(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const handleChange = _editorState => setEditorState(_editorState)

    useImperativeHandle(ref, () => ({
        clearContent: () => setEditorState(EditorState.createEmpty()),
    }))

    useEffect(() => {
        const hashConfig = {
            trigger: '#',
            separator: ' ',
        }
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        const rawToTarget = isHtmlOrMarkdown ? draftToHtml : draftToMarkdown
        const result = rawToTarget(rawContentState, hashConfig, undefined, {
            emptyLineBeforeBlock: true,
        })
        onChange(result)
        /* eslint-disable-next-line */
    }, [editorState])
  
    useEffect(() => {
        if (!initialValue) return
        let contentState = null
        if (isHtmlOrMarkdown) {
            const contentBlock = htmlToDraft(initialValue)
            if (contentBlock) {
                contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            }
        } else {
            contentState = stateFromMarkdown(initialValue)
        }
        if (contentState) {
            const _editorState = EditorState.createWithContent(contentState)
            setEditorState(_editorState)
        }
        /* eslint-disable-next-line */
    }, [])

    return (
        <Editor
            ref={editor}
            wrapperClassName="wysiwyg-wrapper"
            editorClassName="form-control wysiwyg-editor"
            toolbarClassName="wysiwyg-toolbar"
            editorState={editorState}
            onEditorStateChange={handleChange}
            placeholder='Type...'
        />
    )
})

export default DraftEditor
