import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withOrientationChange, isMobileOnly, isTablet, isChrome, isSafari } from 'react-device-detect';
import { getTheme } from 'theme/theme2';
import useLocalStorageState from 'use-local-storage-state';

const ResponsiveContext = React.createContext({
    isPhone: false,
    isTablet: false,
    isPortrait: false,
    isChrome: true,
    isSafari: false,
    selectedThemeKey: 'GREEN',
    theme: undefined,
    primaryColor: '#0f0',
});

export const useResponsiveContext = () => useContext(ResponsiveContext);

const ResponsiveContextContainer = (props) => {
    const { children, isPortrait } = props;

    const [isPhoneMode, setIsPhoneMode] = useState(window.matchMedia('(max-width:600px)').matches);
    useEffect(() => {
        window.matchMedia('(max-width:600px)').addEventListener('change', e => setIsPhoneMode(e.matches));
    }, []);

    let isPhone = false;
    if (isPhoneMode) isPhone = true;
    if (isMobileOnly) isPhone = true;
    if (isTablet) isPhone = true;

    // theme
    const [selectedThemeKey] = useLocalStorageState("themeKey", { ssr: false, defaultValue: "GREEN" });
    const theme = useMemo(() => getTheme(selectedThemeKey), [selectedThemeKey]);
    const primaryColor = useMemo(() => getTheme(selectedThemeKey).palette.primary.main, [selectedThemeKey]);

    return (
        <ResponsiveContext.Provider
            value={{
                isPhone,
                isTablet,
                isPortrait,
                isChrome,
                isSafari,
                selectedThemeKey,
                theme,
                primaryColor,
            }}
        >
            {children}
        </ResponsiveContext.Provider>
    )
}

export default withOrientationChange(ResponsiveContextContainer)
