import { entrypoint } from "../config";
import jwtDecode from "jwt-decode";
import { setCurrentUser } from "providers/currentUser";

export const storeToken = (token) => {
    const decodedToken = jwtDecode(token);
    setCurrentUser({ ...decodedToken, userName: 'Admin' });
    localStorage.setItem('token', token);
    localStorage.setItem('groups', decodedToken.groups);
}

const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    const verified2FA = localStorage.getItem('verified2FA') || false;

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp && typeof decodedToken.exp === 'number') {
            if (Date.now() < decodedToken.exp * 1000) {
                return verified2FA;
            }
        }
    }

    return false;
}

export const authProvider = {
    login: ({username, password}) => {
        const request = new Request(entrypoint + '/login', {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                console.log(response);
                throw new Error(response.statusText);
            }

            return response.json();
        }).then(data => {
            const isEnabled2FA = data['2fa_verified'] !== undefined;
            const token = data.token;
            if (token) {
                storeToken(token);
            }
            return isEnabled2FA;
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('groups');
        setCurrentUser({});
        localStorage.removeItem('verified2FA');
        return Promise.resolve();
    },
    checkAuth: () => {
        const loggedIn = isAuthenticated();
        return loggedIn ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    },
    checkError: (error) => {
        const status = error?.status || error?.response?.status;
        Promise.resolve();
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            localStorage.removeItem('verified2FA');
            return Promise.reject({ redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const groups = localStorage.getItem("groups");
        return Promise.resolve(groups.split(","));
    }
};
