import {TextField} from 'react-admin';
import {ListGuesser, FieldGuesser} from "@api-platform/admin";
import { datagridSx } from 'theme/theme2';

const UserList = () => (
    <ListGuesser datagridSx={{ ...datagridSx }}>
        <FieldGuesser source="firstName"/>
        <FieldGuesser source="lastName"/>
        <FieldGuesser source="mail"/>
        <TextField source="mainAccessGroup.name" label="Main access group"/>
    </ListGuesser>
)

export default UserList;