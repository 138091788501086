import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { spacing } from 'theme/theme2';

const useSelfStyles = makeStyles(theme => ({
    dialogPaper: {
        height: `calc(100vh - ${spacing(4*2)})`,
    },
    dialogPaperPhone: {
        height: '100%',
    },
    form_dialog: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        overflowY: 'auto',
        position: 'relative',
    },
}))

interface Props {
    open?: boolean;
    maxWidth?: string | "md" | "lg" | "xl" | "sm";
    fullWidth?: boolean;
    fullHeight?: boolean;
    fullScreen?: boolean;
    fullContents?: boolean;
    onClose?: () => void;
    onCloseOutsideClick: () => void;
    title: string | React.ReactNode;
    description?: string;
    dialogContents: React.ReactNode;
    dialogActions: React.ReactNode;
    formProps?: any;
}

const MuiModal = ({
    open = false,
    maxWidth = "md",
    fullWidth = false,
    fullHeight = false,
    fullScreen,
    fullContents = false,
    onClose = () => {},
    onCloseOutsideClick,
    title,
    description = '',
    dialogContents = [],
    dialogActions,
    formProps = undefined,
}: Props) => {
    const isPhone = useMediaQuery('(max-width:600px)');
    const classes = useSelfStyles();

    const renderContents = (
        <React.Fragment>
            {title && 
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Grid container direction="row">{title}</Grid>
                    {onClose ? (
                        <IconButton onClick={onClose} sx={{ p: 0, ml: 2 }}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
            }
            <DialogContent dividers>
                {description && <DialogContentText>{description}</DialogContentText>}
                {dialogContents}
            </DialogContent>
            {dialogActions &&
                <DialogActions sx={{ padding: 2, justifyContent: 'center' }}>
                    {dialogActions}
                </DialogActions>
            }
        </React.Fragment>
    )

    return (
        <Dialog
            fullScreen={fullScreen !== undefined ? fullScreen : isPhone}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={onCloseOutsideClick ? onCloseOutsideClick : onClose}
            scroll='paper'
            classes={fullHeight ? { paper: isPhone ? classes.dialogPaperPhone : classes.dialogPaper } : undefined}
        >
            {!fullContents ? (
                formProps ? (
                    <form {...formProps} className={classes.form_dialog}>
                        {renderContents}
                    </form>
                ) : (
                    renderContents
                )
            ) : (
                dialogContents
            )}
        </Dialog>
    )
}

export default MuiModal;
