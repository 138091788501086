import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { Title, useTranslate } from 'react-admin';
import TwoFactorAuthSetting from './TwoFactorAuthSetting';

const Profile = () => {
    const translate = useTranslate();

    return (
        <Card>
            <Title title={translate('pos.profile')} />
            <CardContent>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ p: 2 }}>
                        <TwoFactorAuthSetting />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default Profile;
