import React from 'react';
import { Labeled, useInput, useTranslate } from 'react-admin';
import DraftEditor from 'components/DraftEditor';

const BodyInput = (props) => {
    const {
        field,
        // fieldState: { isTouched, error },
        // formState: { isSubmitted },
        // isRequired,
    } = useInput(props);
    const translate = useTranslate();

    return (
        <Labeled label={translate('pos.email_template.body')}>
            <DraftEditor
                {...field}
                isHtmlOrMarkdown={true}
                initialValue={field.value}
            />
        </Labeled>
    )
}

export default BodyInput;
