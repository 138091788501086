import { EditGuesser, InputGuesser } from "@api-platform/admin";

const ClientEdit = (props) => {
    return (
        <EditGuesser {...props}>
            <InputGuesser source={"name"} />
            <InputGuesser source={"hostUrl"} />
            <InputGuesser source={"apiKey"} />
            <InputGuesser source={"mail"} />
            <InputGuesser source={"additionalInfo"} />
            <InputGuesser source={"createdAt"} />
            <InputGuesser source={"updatedAt"} />
        </EditGuesser>
    );
}

export default ClientEdit;
