import { useState } from 'react';
import { AutocompleteArrayInput, Edit, ReferenceArrayInput, SaveContextProvider, SimpleForm, TextInput, useEditController } from 'react-admin';
import { useApi } from 'contexts/useApi';
import CustomTopToolbar from 'components/CustomTopToolbar';

const UserEdit = () => {
    const { redirect, update } = useApi();
    const { resource, record, isLoading } = useEditController();
    const [saving, setSaving] = useState(false);

    const save = async (data) => {
        setSaving(true);
        // const clients = data.clients || [];
        const recordUpdated = { ...data };
        delete recordUpdated.clients;
        const { data: result, error } = await update(resource, record.id, data, record);
        setSaving(false);
        if (!error) {
            redirect('show', resource, result.id);
        }
        return result;
    }

    if (isLoading) return null;
    return (
        <Edit actions={<CustomTopToolbar type='edit' />}>
            <SaveContextProvider value={{ save, saving }}>
                <SimpleForm>
                    <TextInput source='firstName' autoFocus />
                    <TextInput source='lastName' />
                    <TextInput source='mail' />
                    <ReferenceArrayInput source='clients' reference='clients'>
                        <AutocompleteArrayInput optionText='name' optionValue='id' />
                    </ReferenceArrayInput>
                </SimpleForm>
            </SaveContextProvider>
        </Edit>
    );
}

export default UserEdit

