import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Box, FormHelperText } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useApi } from 'contexts/useApi';
import { storeToken } from 'providers/authProvider';

const TwoFactorAuthCodeInput = ({
    setLoading = (isLoading) => { },
    onComplete = (isVerified) => { },
}) => {
    const translate = useTranslate();
    const [otp, setOtp] = useState('');
    const [isVerified, setIsVerified] = useState();
    const { postByNonHydra } = useApi();

    const handleChange = async (res) => {
        setOtp(res);
        if (res.length === 6) {
            setLoading(true);
            const formData = new FormData();
            formData.append('_auth_code', res);
            const data = await postByNonHydra('2fa_check', formData);
            if (data && data['2fa_verified']) {
                storeToken(data.token);
                setIsVerified(true);
                onComplete(true);
            } else {
                setIsVerified(false);
            }
            setLoading(false);
        }
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    isInputNum
                    inputStyle={{ width: 32, height: 36 }}
                />
            </Box>
            {isVerified === false && <FormHelperText error>{translate('pos.two_factor_auth.not_verified')}</FormHelperText>}
            {isVerified && <FormHelperText>{translate('pos.two_factor_auth.verified')}</FormHelperText>}
        </Box>
    )
}

export default TwoFactorAuthCodeInput;
