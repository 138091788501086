import axios from 'axios';
import JSONBigInt from 'json-bigint';

axios.interceptors.request.use(req => {
    const token = localStorage.getItem("token");
    if (token) req.headers = { Authorization: `Bearer ${token}` };
    req.transformResponse = [data => data];
    return req;
});

axios.interceptors.response.use(res => {
    if (res.data.byteLength) return res;
    if (typeof res.data === 'string' && res.data[0] !== '{') return res;
    res.data = JSONBigInt({ storeAsString: true }).parse(res.data);
    return res;
});

export default axios;
