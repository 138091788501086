import React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { datagridSx } from 'theme/theme2';

const EmailTemplatesList = () => {
    return (
        <ListGuesser datagridSx={{ ...datagridSx }}>
            <FieldGuesser source="title" />
            <FieldGuesser source="subject" />
            <FieldGuesser source="body" />
        </ListGuesser>
    )
}

export default EmailTemplatesList;
