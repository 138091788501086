import React, { useState } from 'react';
import { Edit, SaveContextProvider, SimpleForm, TextInput, useEditController } from 'react-admin';
import { useApi } from 'contexts/useApi';
import CustomTopToolbar from 'components/CustomTopToolbar';
import BodyInput from '../custom/BodyInput';

const EmailTemplatesEdit = () => {
    const { redirect, update } = useApi();
    const { resource, record, isLoading } = useEditController();
    const [saving, setSaving] = useState(false);

    const save = async (data) => {
        setSaving(true);
        const { data: result, error } = await update(resource, record.id, data, record);
        setSaving(false);
        if (!error) redirect('show', resource, result.id);
        return result;
    }

    if (isLoading) return null;
    return (
        <Edit actions={<CustomTopToolbar type='edit' />}>
            <SaveContextProvider value={{ save, saving }}>
                <SimpleForm>
                    <TextInput source='title' autoFocus />
                    <TextInput source='subject' />
                    <BodyInput source='body' />
                </SimpleForm>
            </SaveContextProvider>
        </Edit>
    )
}

export default EmailTemplatesEdit;
