import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { TextField } from "react-admin";

const UserShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="firstName" />
        <FieldGuesser source="lastName" />
        <FieldGuesser source="mail" />
        <TextField source="mainAccessGroup.name" />
    </ShowGuesser>
);

export default UserShow;
