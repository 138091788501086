import { TranslationMessages } from 'react-admin';
import deutschMessages from 'ra-language-german';

const customDeutschMessages: TranslationMessages = {
    ...deutschMessages,
    pos: {
        noLayout: {
            language: 'Sprache',
            saveLogin: 'Meinen Login auf diesem Gerät speichern',
        },
        dashboard: {
            name: 'Dashboard',
            welcome: {
                title: 'Willkommen',
                subtitle: 'Entdecken Sie das neue Brickfox Client-Hub',
            },
        },
        profile: "Profile",
        email_template: {
            name: 'Email Template',
            title: 'Titel',
            subject: 'Betreff',
            body: 'Inhalt',
        },
        send_email: 'Email Senden',
        cancel: 'Abbrechen',
        save: 'Speichern',
        footer: {
            msg1: "Kunde"
        },
        logged_out: 'Sie wurden erfolgreich ausgeloggt.',
        good_day: 'Bis zum nächsten mal.',
        go_to_login: 'Wieder einloggen',
        no_options: 'Keine Auswahl',
        two_factor_auth: {
            title: 'Zwei-Faktor-Authentifizierung:',
            description1: 'Wir schützen Ihren Zugang mit der',
            description2: 'Zwei-Faktor-Authentifizierung von Google Authenticator',
            description3: 'Bitte scannen Sie den QR-Code in der Authenticator APP.',
            with_google_auth_app: 'Zwei-Faktor-Authentifizierung mit Google Authenticator App',
            turn_on: 'Einschalten',
            turn_off: 'Ausschalten',
            scan_qr_code: 'Bitte scannen Sie diesen QR-Code in ihrer Google Authenticator App',
            after_scan: 'Geben Sie nach dem Scannen des QR-Codes den Google Authenticator Code hier ein.',
            not_verified: 'Die Authentifizierung ist fehlgeschlagen, bitte versuchen Sie es erneut.',
            verified: 'Authentifizierung erfolgreich !',
            confirm_disable_title: 'Bestätigen',
            confirm_disable: 'Sind Sie sicher, dass Sie die Zwei-Faktor-Authentifizierung abschalten möchten ?',
            disable: 'Abschalten',
            disable_success: 'Zwei-Faktor-Authentifizierung wird nicht mehr verwendet.',
            disable_failure: 'Abschalten der Zwei-Faktor-Authentication fehlgeschlagen.',
            enable_failure: 'Die Zwei-Faktor-Authentication ist fehlgeschlagen.',
        },
        forgot_your_password: 'Passwort vergessen ?',
    },
    messages: {
        success: 'Abgeschlossen',
        error: 'Fehler',
        duplicated: 'Schon vorhanden',
        constraint: 'Eingeschränkt',
    },
    client: {
        amazonSellerPartner: "Amazon Seller Partner API",
        amazonSellerPartnerConnect: "Verbinden",
        amazonSellerPartnerConnected: "Verbunden",
        refreshToken: 'Refresh Token',
        notification: {
            amazonConnectError: "Fehler beim Verbinden mit Amazon, bitte versuchen Sie es erneut",
            amazonNotConnected: "Konto ist noch nicht verbunden! Bitte fahren Sie mit dem Verbindungsprozess im Amazon Popup fort",
        }
    },
    general: {
        tab: {
            title: "Allgemein"
        },
        country: "Land:",
        shopsName: "Vertriebskanal - Name"
    },
    menu: {
        users: "Benutzer",
        clients: "Kunden"
    },
};

export default customDeutschMessages;
